import * as React from "react"

import Layout from "../components/layout/layout"
import CapabilitiesSection from "../components/sections/capabilities-section"
import Seo from "../components/seo"
import BenefitsSection from "../components/sections/benefits-section"
import QuickQuoteSection from "../components/sections/quick-quote-section"
import PageTeamHeroSection from "../components/sections/page-team-hero-section"
import PageTeamSection from "../components/sections/page-team-section"

const TeamPage = () => (
  <Layout>
    <Seo title="Meet Your Team" />
    <PageTeamHeroSection />
    <PageTeamSection />
    <BenefitsSection />
    <QuickQuoteSection />
    <CapabilitiesSection />
  </Layout>
)

export default TeamPage
